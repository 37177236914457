import React, { useEffect, createRef } from "react";

import lottie from "lottie-web";

const Animation = (props) => {

    const{ animation, delay, speed } = props

    const delayTime = delay | 0
    const speedTime = speed | 1

  let animationContainer = createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      speed: speedTime,
      animationData: animation
    });
    setTimeout(function(){ anim.play(); }, delayTime);
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <div className="animation">
        <div className="animation-container" ref={animationContainer} />
    </div>
  );
};

export default Animation;

