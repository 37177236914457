import React from "react"
import { Link } from "gatsby"
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Footer from '../components/Footer'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from '../components/Commons'
import { media } from '../tokens'
import {Container,Row,Col} from 'react-bootstrap'
import Animation from '../components/Animation'
import penguinAnimation from "../../content/animations/Nuages.json";
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { colors } from '../tokens'
import Image from 'gatsby-image'
import SEO from '../components/SEO'


const SiteContent = styled.div`
  margin: 0 0;
  align-items: center;
  justify-content: center;

  @media ${media.minMedium} {
    margin: 20px 0;
  }

  .animation{
    position:absolute;
  }
`

const BackgroundContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const BackgroundOverlay = styled.div`
  position: absolute;
  display: table;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: rgb(127,212,150);
  background: linear-gradient(90deg, rgba(127,212,227,0.7) 0%, rgba(9,121,35,0) 30%, rgba(9,121,35,0) 70%, rgba(157,128,247,0.7) 100%);
`

const ImageParent = styled.div`
    width:400px;
    height:290px;

    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
    transition: 0.2s;

    :hover{
      //animation: ScaleUpDown 0.5s ease-in-out stay;
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    
    @keyframes MoveUpDown {
      0%, 100% {
        margin-top: 0px;
      }
      50% {
        margin-top: 20px;
      }
    }

    @keyframes ScaleUpDown {
      0%,  {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
      }
      100% {
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
`

const LogoImageParent = styled.div`
    position:absolute;
    width:400px;
    height:355px;
    bottom:15px;
    right:25%;
    left:50%;
    margin-left:-200px;

    transition: 0.2s;

    :hover{
      animation: none;
      width:420px;
      height:375px;
      bottom:10px;
      margin-left:-210px;
    }

    .image-style{
      animation: MoveUpDown 4s ease-in-out infinite;

      @keyframes MoveUpDown {
        0%, 100% {
          bottom: 5px;
        }
        50% {
          bottom: 25px;
        }
      }
    }
    
`

const AssetImageParent = styled(ImageParent)`
  animation: MoveUpDown 3s ease-in-out infinite;
`

const GameImageParent = styled(ImageParent)`
  animation: MoveUpDown 3s ease-in-out infinite;
`

const CloudRow = styled(Row)`
  align-items: center;
  justify-content: center;
  height: 400px;
`

const CloudAnimationParentRight = styled.div`
    position:absolute;
    width:400px;
    height:100px;
    bottom:700px;
    right:25%;
    left:50%;
    margin-left:00px;

    @media ${media.maxLarge} {
      margin-left:-60px;
      bottom:650px;
    }

    @media ${media.maxMedium} {
      margin-left:-150px;
    }

    .animation-container{
        
    }
`

const CloudAnimationParentLeft = styled.div`
    transform: scaleX(-1);
    position:absolute;
    width:400px;
    height:100px;
    bottom:700px;
    right:25%;
    left:50%;
    margin-left:-400px;

    @media ${media.maxLarge} {
      margin-left:-340px;
      bottom:650px;
    }

    @media ${media.maxMedium} {
      margin-left:-250px;
    }

    .animation-container{
        
    }
`

export default () =>{
  const backgroundImage = useSiteImages("SplashScreenBackground.png").fluid.src
  const logoFluid = useSiteImages("dypsloom-logo-color-stroke.png").fluid
  const assetFluid = useSiteImages("ASSETS.png").fluid
  const gameFluid = useSiteImages("GAMES.png").fluid
  
  
 return (
  <>
    {/*<Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>*/}
    
    <SEO>
      <meta name="google-site-verification" content="bwg5-FhX6ry-yi7Om0mS_QI_Xtktk3iHc_K-bDpqeA0" />
    </SEO>
    <GlobalStyle />
    <BackgroundContainer style={{ backgroundImage: `url("${backgroundImage}")` }}>

      <BackgroundOverlay></BackgroundOverlay>
      <CloudAnimationParentRight>
        <Animation animation={penguinAnimation} delay={500} speed={0.7}></Animation>
      </CloudAnimationParentRight>
      <CloudAnimationParentLeft>
        <Animation animation={penguinAnimation} speed={0.7}></Animation>
      </CloudAnimationParentLeft>
    <SiteContent>
      
      <Container>
        
        <LogoImageParent>
          <Link to="/Studio">
            <Image className="image-style" fluid={logoFluid} ></Image>
          </Link>
        </LogoImageParent>

        <CloudRow md="2">
          <GameImageParent>
            <Link to="/Games">
              <Image className="image-style" fluid={gameFluid} ></Image>
            </Link>
          </GameImageParent>
          <AssetImageParent>
            <Link to="/Assets">
              <Image className="image-style" fluid={assetFluid} ></Image>
            </Link>
          </AssetImageParent>
          
        </CloudRow>
        
      </Container>
    </SiteContent>
      

    </BackgroundContainer>

    

    
    <Footer hideLogo="true"/>
  </>
)
}